var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-topNav-links" }, [
    _c("i", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.btn.left,
          expression: "btn.left",
        },
      ],
      staticClass: "el-icon-back",
      on: {
        click: function ($event) {
          return _vm.move("forward")
        },
      },
    }),
    _c(
      "div",
      { staticClass: "router-link-wrap" },
      _vm._l(_vm.topNavList, function (item) {
        return _c("router-link", { key: item.id, attrs: { to: item.path } }, [
          _vm._v(_vm._s(item.text)),
        ])
      }),
      1
    ),
    _c("i", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.btn.right,
          expression: "btn.right",
        },
      ],
      staticClass: "el-icon-right",
      on: {
        click: function ($event) {
          return _vm.move("back")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }