import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";
export default new Vuex.Store({
  state: {
    userInfo: "",
    currentModule: "", // 顶部导航菜单，左边当前模块名称
    isShowMenu: true,
    rtcOptions: "",
    sessionArr: [], // 当前所有的会话
    mediaNumber: 4, // 当前分屏数量
    tempraryShow: false, //临时隐藏一些功能；Notice:如果遇到值不更新，则清空sessionStorage
    callDialog: {
      show: false,
      name: "",
      avatar: "",
      isCalled: false, //是否被叫
      callerId: null,
      invited: false, //是否是被邀请
    },
    rongCloud: {
      channelId: "",
      token: "",
    },
    chatRoomMessages: [],
    // 用于记录所有在线用户
    onlineUsers: {},
    // 每当RC发一条客户登录、退出信息，就会记到这里，方便更新在线列表
    allUsersFromRcSent: {},
    isSplitScreen: false,
  },
  getters: {
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getCurrent: (state) => {
      return state.currentModule;
    },
    getIsShowMenu: (state) => {
      return state.isShowMenu;
    },
    getRtcOptions: (state) => {
      return state.rtcOptions;
    },
    getSessionArr: (state) => {
      return state.sessionArr;
    },
    getMediaNumber: (state) => {
      return state.mediaNumber;
    },
    getTempraryShow: (state) => {
      return state.tempraryShow;
    },
    getCallDialogData: (state) => {
      return state.callDialog;
    },
    getRongCloud: (state) => {
      return state.rongCloud;
    },
    getChatRoomMessages: (state) => {
      return state.chatRoomMessages;
    },
    getOnlineUsers: (state) => {
      return state.onlineUsers;
    },
    getAllUsersFromRcSent: (state) => {
      return state.allUsersFromRcSent;
    },
    getIsSplitScreen(state) {
      return state.isSplitScreen;
    },
  },
  mutations: {
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    setCurrentModule: (state, module) => {
      state.currentModule = module;
    },
    setIsShowMenu: (state, showMenu) => {
      state.isShowMenu = showMenu;
    },
    setRtcOptions: (state, options) => {
      state.rtcOptions = options;
    },
    setSessionArr: (state, sessionArr) => {
      state.sessionArr = sessionArr;
    },
    setMediaNumber: (state, mediaNumber) => {
      state.mediaNumber = mediaNumber;
    },
    setCallDialogData: (state, payload) => {
      state.callDialog = Object.assign(state.callDialog, payload);
    },
    setRongCloudData: (state, payload) => {
      state.rongCloud = Object.assign(state.rongCloud, payload);
    },
    setChatRoomMessages: (state, payload) => {
      state.chatRoomMessages = [...payload];
    },
    setOnlineUsers: (state, payload) => {
      state.onlineUsers = Object.assign({}, state.onlineUsers, payload);
    },
    setAllUsersFromRcSent: (state, payload) => {
      state.allUsersFromRcSent = Object.assign(
        {},
        state.allUsersFromRcSent,
        payload
      );
    },
    setIsSplitScreen: (state, payload) => {
      return (state.isSplitScreen = payload);
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
