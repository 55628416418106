<template>
  <div class="rong-cloud-initial">
    <!-- rong-cloud-test-page
    <el-button type="primary" @click="sendCall">拨通test的电话</el-button>
    <el-button @click="accept">接听</el-button>
    <el-button @click="hangup">挂断</el-button> -->
    <!-- <div id="rong-cloud-tv"></div> -->
    <call-dialog
      v-model="getCallDialogData.show"
      :isCalled="getCallDialogData.isCalled"
      :callName="getCallDialogData.name"
      :avatar="getCallDialogData.avatar"
      @handleAnswer="accept"
      @handleRefuse="hangUpOtherCall"
      @handleCancel="hangUpMyCall"
    >
    </call-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CallDialog from "@/components/Call";
//导入 IMLib 5.X
import * as RongIMLib from "@rongcloud/imlib-next";
// 导入 RTCLib、CallLib
import {
  installer as rtcInstaller,
  RCRTCClient,
  RCTrack,
  RCFrameRate,
  RCResolution,
} from "@rongcloud/plugin-rtc";

import {
  installer as callInstaller,
  RCCallClient,
  RCCallSession,
  RCCallErrorCode,
  IDeviceChangeParams,
  ISessionListener,
  IEndSummary,
  ISenderInfo,
  IMuteUser,
  IInvitedUsers,
  RCCallLanguage,
  RCCallEndReason,
  RCCallMediaType,
  IOfflineRecord,
  RCCallSessionState,
} from "@rongcloud/plugin-call";

const testUser = {
  token:
    "Zy4SJIXnGp3pwnj46Lx/vocymGqGi+jQO4n2pelwFHE=@o543.cn.rongnav.com;o543.cn.rongcfg.com",
  id: "test",
};
// const myself = {
//   token:
//     "1dz5iWvuRxSKAxVAD3nlz58XV/5iyQnq@o543.cn.rongnav.com;o543.cn.rongcfg.com",
//   id: "xc",
// };

export default {
  components: {
    CallDialog,
  },
  data() {
    return {
      // Rong-cloud-constant
      RongIMLib: null,
      RTCClient: null,
      Caller: null,
      calledSession: null,
      mediaType: 2,
      // call-dialog-params
      // callDialog: {
      //   visible: false,
      //   callName: "",
      //   rtmCommonChannel: null,
      //   isJoin: false,
      // },
      myself: {
        token: "",
        id: "",
      },
      appkey: "",
      chatRoomMessages: [],
    };
  },
  mounted() {
    this.initial();
  },
  computed: {
    ...mapGetters([
      "getUserInfo",
      "getCallDialogData",
      "getChatRoomMessages",
      "getOnlineUsers",
      "getAllUsersFromRcSent",
    ]),
  },
  methods: {
    async initial() {
      this.appkey = this.getUserInfo.rongAppKey;
      // IM 客户端初始化（IMLib 5.X）
      RongIMLib.init({
        appkey: this.appkey,
      });

      /**
       * 监听消息通知
       */
      // 公共对象
      window.GLOBAL = {};
      const _this = this;
      const Events = RongIMLib.Events;
      window.GLOBAL.Events = Events;
      // 消息监听
      RongIMLib.addEventListener(Events.MESSAGES, (event) => {
        // 处理消息队列
        event.messages.map((message) => {
          console.log(message);
          // 文本消息
          if (message.messageType === "RC:TxtMsg") {
            const content = JSON.parse(message.content.content);
            // 安卓发来的顶号信息，id匹配则跳转到登录页
            if (
              content?.platform === "android" &&
              content?.userId == _this.getUserInfo.id
            ) {
              _this.logout();
              return;
            }
            Object.keys(content).map((key) => {
              const target = content[key];
              // 被叫-远端呼叫
              if (key === "rtc_call") {
                // 存储被叫信息
                _this.$store.commit("setCallDialogData", {
                  show: true,
                  avatar: target.avatar,
                  name: target.name,
                  isCalled: true,
                  callerId: target.userId,
                  // Web端=>客户端协作的时候，web端邀请web端，此时需要根据  platform: "join"
                  // 区分；在监听call的时候，存储类型；在接听和挂断的时候加个from用以区分不同类型
                  invited: target.platform === "join",
                });
                // 存储channelId
                _this.$store.commit("setRongCloudData", {
                  channelId: target.channel,
                });
              }
              // 被叫-远端挂断
              else if (key === "rtc_refuse") {
                // 存储被叫信息
                _this.$store.commit("setCallDialogData", {
                  show: false,
                  avatar: "",
                  name: "",
                  isCalled: true,
                  callerId: null,
                });

                // 只有远端主叫拒绝，清空当前的聊天室id
                if (!target.from) {
                  // 存储channelId
                  _this.$store.commit("setRongCloudData", {
                    channelId: "",
                  });
                }
              }
              // 远端接听
              else if (key === "rtc_accept") {
                // 邀请 or 远端主叫
                _this.$store.commit("setCallDialogData", {
                  show: false,
                  avatar: "",
                  name: "",
                  isCalled: false,
                });
                // 只有远端主叫跳页面
                if (!target.from) {
                  // 电话跳转的当前协作，加入源标记
                  _this.gotoCurrentCollaboration();
                }
              }
              // ------------------------------聊天室消息处理-----------------------
              if (key === "type") {
                if (content.type == "chat") {
                  content.chatContent.oneself = 0;
                  // 取出历史信息
                  const chatHistory = _this.getChatRoomMessages;
                  // 设置信息
                  _this.$store.commit("setChatRoomMessages", [
                    ...chatHistory,
                    content.chatContent,
                  ]);
                  _this.contentToBottom();
                }
              }
            });
          }
          console.log(event.messages);
        });
      });

      /**
       * 监听 IM 连接状态变化
       */
      RongIMLib.addEventListener(Events.CONNECTING, () => {
        console.log("onConnecting");
      });
      RongIMLib.addEventListener(Events.CONNECTED, () => {
        console.log("onConnected");
      });
      RongIMLib.addEventListener(Events.DISCONNECT, (status) => {
        if (status === 31010) {
          // 清空登录token，导航守卫会禁止跳转系统页面
          _this.logout();
        }
        console.log("连接中断，需要业务层进行重连处理 ->", status);
      });

      // 初始化 RCRTCClient，初始化过程推荐放在建立连接之前
      this.RTCClient = RongIMLib.installPlugin(rtcInstaller);
      this.RongIMLib = RongIMLib;

      // 公共对象
      window.GLOBAL.CommonRongIMLib = RongIMLib;
      window.GLOBAL.CommonRtcClient = this.RTCClient;
      // 建立IM链接后才可以进行呼叫；
      this.connect();
    },
    logout() {
      this.$message.error("当前用户在其他移动设备上登录!");
      // 清空登录token，导航守卫会禁止跳转系统页面
      localStorage.removeItem("eleToken");
      this.$router.push("/login");
      // 调logout接口
      this.$axios({
        method: "post",
        url: "api/csp/v1/tenant/user/logout",
      }).then((response) => {
        let res = response.data;
        if (!res.status) {
          localStorage.setItem("eleToken", "");
          this.$router.push({ path: "/login" });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
            duration: 1000,
          });
        }
      });
      // 清空IM
      try {
        const roomId = this.getUserInfo.rtmChannel;

        // 离开全局room，断开连接
        this.RongIMLib.quitChatRoom(roomId).then((res) => {
          // 退出聊天室成功
          if (res.code === 0) {
            console.log("退出聊天室 " + roomId);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async joinTopFloorChatRoom() {
      const count = -1;
      const roomId = this.getUserInfo.rtmChannel;

      this.RongIMLib.joinChatRoom(roomId, {
        count,
      })
        .then((res) => {
          // 加入聊天室成功
          if (
            res.code === this.RongIMLib.ErrorCode.SUCCESS &&
            sessionStorage.getItem("SEND_LOGIN_MSG_ONCE")
          ) {
            // 定时器位了解决立即发消息，客户端有时候收不到的问题
            // 发送登录消息
            setTimeout(() => {
              this.sendLoginMsg(roomId);
            }, 2000);
          } else {
            console.log(res.code, res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.usersObserver();
    },
    usersObserver() {
      const CHATROOM = this.RongIMLib.Events.CHATROOM;
      const _this = this;

      // 用户加入退出监听
      this.RongIMLib.addEventListener(CHATROOM, (event) => {
        if (event.userChange) {
          const change = event.userChange;
          const users = _this.getOnlineUsers;
          const allUsers = _this.getAllUsersFromRcSent;
          // 每次登录都是相同chatroomId，所以忽略匹配；
          // change={chatroomId: "31cc3c4e157f4bc4a8fe10b2d5eb4c00",users: {120: 0}}
          Object.keys(change.users).map((id) => {
            if (!Object.keys(users).length) {
              users[id] = 1;
            } else {
              // change.user[id]=1，新加入用户，遍历没有匹配到，则追加
              if (change.users[id] && !users[id]) {
                users[id] = 1;
              }
              // change.users[id]=0，用户退出；匹配到则删除
              else if (users[id]) {
                delete users[id];
              }
            }
            allUsers[id] = change.users[id];
          });
          // 记录在线用户
          _this.$store.commit("setOnlineUsers", users);
          // 记录RC有登录退出记录的用户
          _this.$store.commit("setAllUsersFromRcSent", allUsers);
          console.log("加入退出的用户通知:", event.userChange, users, allUsers);
        }
      });
    },
    async sendLoginMsg(roomId) {
      const userId = this.getUserInfo.id.toString();

      const msg = { mode: "login", userId, platform: "web" };

      await this.sendMessage(msg, userId);
      sessionStorage.removeItem("SEND_LOGIN_MSG_ONCE");
    },
    connect() {
      let userToken = this.myself;

      this.myself.token = this.getUserInfo.rongRtmToken;
      this.myself.id = this.getUserInfo.id;

      this.RongIMLib.connect(userToken.token)
        .then((user) => {
          console.log("链接成功, 链接用户 id 为: ", user.data.userId);
          window.GLOBAL.state = "ready";
          // 加入登录后的channelId，最顶层的会议室
          this.joinTopFloorChatRoom();
        })
        .catch((error) => {
          console.log("链接失败: ", error.code, error.msg);
        });
    },
    contentToBottom: () => {
      setTimeout(() => {
        document.querySelector(".chat-info").scrollTop = 99999999999;
      }, 0);
    },
    // 被叫-挂断
    async hangUpOtherCall() {
      const { callerId, invited } = this.getCallDialogData;

      this.$store.commit("setCallDialogData", {
        show: false,
        avatar: "",
        name: "",
        isCalled: true,
        from: invited,
      });

      const message = {
        rtc_refuse: {
          userId: this.myself.id,
        },
      };
      await this.sendMessage(message, callerId);
    },
    // 主叫-挂断：:这里有可能是客户端发送的消息；也有可能是web端邀请发送的消息
    async hangUpMyCall() {
      this.$store.commit("setCallDialogData", {
        show: false,
        avatar: "",
        name: "",
        isCalled: false,
      });

      const { callerId } = this.getCallDialogData;
      const message = {
        rtc_refuse: {
          userId: this.myself.id,
        },
      };
      await this.sendMessage(message, callerId);
    },

    // 被叫-接听:这里有可能是客户端发送的消息；也有可能是web端邀请发送的消息
    async accept() {
      const { callerId, invited } = this.getCallDialogData;

      this.$store.commit("setCallDialogData", {
        show: false,
        isCalled: false,
        from: invited,
      });

      const message = {
        rtc_accept: {
          userId: this.myself.id,
        },
      };
      await this.sendMessage(message, callerId);
      this.$message.success("接听成功");
      // 电话跳转的当前协作，加入源标记
      this.gotoCurrentCollaboration();
    },
    // Rong Cloud IM message
    sendMessage(msg, targetId, isChatRoom) {
      const { CHATROOM, PRIVATE } = this.RongIMLib.ConversationType;
      return new Promise((resolve, reject) => {
        // 指定消息发送的目标会话
        const conversation = {
          // targetId
          targetId,
          // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
          conversationType: isChatRoom ? CHATROOM : PRIVATE,
        };

        // 构建文本消息
        const message = new this.RongIMLib.TextMessage({
          content: JSON.stringify(msg),
        });
        const options = {
          // 如果需要发送 @ 消息，isMentioned 需设置为 true
          isMentioned: true,
          // 消息发送前的回调，可以使用此回调返回的 message 用于列表渲染
          onSendBefore: (message) => {
            console.log("消息发送前的回调", message);
          },
        };
        // 发送消息
        this.RongIMLib.sendMessage(conversation, message, options)
          .then(({ code, data }) => {
            if (code === 0) {
              resolve(resolve);
              console.log("消息发送成功：", data);
            } else {
              reject("消息发送失败：", code);
              console.log("消息发送失败：", code);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    cleanContainer() {
      document.getElementById("rong-cloud-tv").innerHTML = "";
    },
    gotoCurrentCollaboration() {
      this.$router.push({
        path: "/remoteCollaboration/currentCollaboration",
        query: {
          origin: "call",
        },
      });
      // 接电话时，当前页面处在当前协作，需要刷新触发初始化
      if (
        window.location.hash.search(
          /remoteCollaboration\/currentCollaboration/
        ) > -1
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
  },
};
</script>

<style>
.rong-cloud-initial {
  margin: 0 auto;
}
</style>
