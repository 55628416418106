import axios from "axios";
import { Loading, Message } from "element-ui";
import router from "@/router";

let loading;
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "加载中...",
    background: "rgba(0, 0, 0, 0.7)",
  });
}

function endLoading() {
  loading.close();
}
axios.defaults.withCredentials = true;
// 请求响应
axios.interceptors.request.use(
  (config) => {
    // 加载加载中动画
    if (!config.closeLoading) {
      startLoading();
    }

    if (localStorage.eleToken) {
      config.headers["x-token"] = localStorage.eleToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 请求拦截
axios.interceptors.response.use(
  (response) => {
    endLoading();
    return response;
  },
  (error) => {
    Message.error(error.response.data);
    endLoading();

    // 获取状态码
    const { status } = error.response;
    if (status === 401) {
      Message.error("token失效，请重新登录");
      // 清除token
      localStorage.removeItem("eleToken");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default axios;
