<template>
  <el-dialog
    class="js-dialog-form js-dialog-call"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :show-close="false"
    width="360px"
  >
    <i class="icon-user">
      <img :src="avatar || require('../assets/images/userImg.png')" alt="" />
    </i>
    <p class="call-name">{{ callName }}</p>
    <p class="info">{{ isCalledTitle }}</p>
    <!-- 被叫 -->
    <template v-if="isCalled">
      <div>
        <i class="icon icon-answer" @click.stop="handleAnswer"></i>
        <i class="icon icon-refuse" @click.stop="handleRefuse"></i>
      </div>
    </template>
    <!-- 主叫 -->
    <template v-else>
      <div>
        <i class="icon icon-refuse" @click.stop="handleCancel"></i>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import defaultAvatar from "../assets/images/18.png";

export default {
  name: "",
  props: ["value", "callName", "isCalled", "avatar", "name"],
  data() {
    return {};
  },
  computed: {
    isCalledTitle() {
      return this.isCalled ? "来电了……" : "呼叫中……";
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  mounted() {},
  methods: {
    handleAnswer() {
      this.$emit("handleAnswer");
    },
    handleRefuse() {
      this.$emit("handleRefuse");
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/initiateCollaboration.scss";
</style>
<style lang="scss">
.js-dialog-call {
  // .el-dialog {
  //   background: url("../assets/images/18.png") no-repeat center;
  //   background-size: 100%;
  // }
  .el-dialog__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
</style>
