<template>
  <div class="js-topNav-links">
    <i class="el-icon-back" @click="move('forward')" v-show="btn.left"></i>
    <div class="router-link-wrap">
      <router-link v-for="item in topNavList" :to="item.path" :key="item.id">{{
        item.text
      }}</router-link>
    </div>
    <i class="el-icon-right" @click="move('back')" v-show="btn.right"></i>
  </div>
</template>

<script>
export default {
  name: "Jiashi10Topnav",
  props: ["topNavList"],
  data() {
    return {
      btn: {
        left: false,
        right: false,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    move(dic) {
      const scrollBar = document.querySelector(".router-link-wrap");
      if (dic === "forward") {
        scrollBar.scrollLeft -= 50;
      } else if (dic === "back") {
        scrollBar.scrollLeft += 50;
      }
    },
    // 控制按钮显示
    init() {
      const scrollBar = document.querySelector(".router-link-wrap");
      this.controlBtns();
      scrollBar.addEventListener("scroll", () => {
        this.controlBtns();
      });
    },
    controlBtns() {
      const scrollBar = document.querySelector(".router-link-wrap");
      const childCount = scrollBar.getElementsByTagName('a').length;
      const min = 0;
      const max = 130 * childCount - scrollBar.clientWidth;
      const size = 10;
      const currentScrollLeft = scrollBar.scrollLeft;
      if(max<0){
        return false
      }

      if (currentScrollLeft <= min + size) {
        this.btn.left = false;
        this.btn.right = true;
      } else if (currentScrollLeft >= max - size) {
        this.btn.left = true;
        this.btn.right = false;
      } else {
        this.btn.left = true;
        this.btn.right = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/nav.scss";
.js-topNav-links {
  position: relative;
  padding: 0 28px;
  flex: 1;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  .el-icon-right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .el-icon-back {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .router-link-wrap {
    flex: 1;
    overflow-x: auto;
    display: flex;
    justify-content: center;
  }
}
</style>
