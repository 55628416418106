<template>
  <el-dialog ref="feedBackDialog" class="js-dialog-form" title="问题及建议反馈" :visible.sync="dialogVisible" append-to-body width="560px">
    <ul>
      <li class="text-top">
        <div class="label common-tip"><i>*</i>备注</div>
        <el-input v-model="textarea" type="textarea" :rows="4" placeholder="请输入备注关键字"></el-input>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Jiashi10Feedback',
  props: ['value'],
  data() {
    return {
      textarea: ''
    };
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
    
  },
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      console.log('确定……')
      // this.handleCancel()
      console.log(this.textarea)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>