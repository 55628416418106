var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-layout" },
    [
      _c("js-nav"),
      _c("router-view", { staticClass: "js-content" }),
      _c("RongCloud"),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "footer" }, [
      _vm._v(" Copyright © 2021 嘉仕企业服务平台 All Rights Reserved "),
      _c("span", [_vm._v("京ICP备2022024506号-1")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }