var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-nav" },
    [
      _c(
        "div",
        { staticClass: "js-topNav" },
        [
          _c("div", { staticClass: "js-topNav-menu" }, [
            _c("div", { ref: "menu", staticClass: "menu" }, [
              _c("span", { staticClass: "current-menu" }, [
                _vm._v(_vm._s(_vm.getCurrent) + " "),
              ]),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/show-menu.png"),
                  alt: "",
                },
              }),
            ]),
          ]),
          _c("top-nav", { attrs: { topNavList: _vm.topNavList } }),
          _c(
            "div",
            { staticClass: "js-topNav-about" },
            [
              _c(
                "el-dropdown",
                {
                  staticClass: "drop-about",
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c("el-avatar", {
                    staticClass: "js-topNav-avtar",
                    attrs: { size: 40, src: _vm.getUserInfo.fullAvatar },
                  }),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: 1 } }, [
                        _vm._v("个人信息修改"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: 2 } }, [
                        _vm._v("密码修改"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: 0 } }, [
                        _vm._v("退出"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("silder-nav", {
        ref: "silderNav",
        model: {
          value: _vm.silderNavFlag,
          callback: function ($$v) {
            _vm.silderNavFlag = $$v
          },
          expression: "silderNavFlag",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }