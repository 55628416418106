var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-dialog-call",
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: "360px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("i", { staticClass: "icon-user" }, [
        _c("img", {
          attrs: {
            src: _vm.avatar || require("../assets/images/userImg.png"),
            alt: "",
          },
        }),
      ]),
      _c("p", { staticClass: "call-name" }, [_vm._v(_vm._s(_vm.callName))]),
      _c("p", { staticClass: "info" }, [_vm._v(_vm._s(_vm.isCalledTitle))]),
      _vm.isCalled
        ? [
            _c("div", [
              _c("i", {
                staticClass: "icon icon-answer",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleAnswer.apply(null, arguments)
                  },
                },
              }),
              _c("i", {
                staticClass: "icon icon-refuse",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleRefuse.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]
        : [
            _c("div", [
              _c("i", {
                staticClass: "icon icon-refuse",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCancel.apply(null, arguments)
                  },
                },
              }),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }