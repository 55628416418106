<template>
  <div
    ref="jsSilderNav"
    class="js-silder-nav animate__animated animate__slideInDown"
    v-show="dialogVisible"
    @mouseleave="handleMouseleave($event)"
  >
    <img class="jaresh-logo" src="@/assets/images/jaresh.png" alt="" />
    <div class="scroller">
      <div class="router-links">
        <router-link v-for="item in navList" :key="item.id" :to="item.path"
          ><i class="nav-icon"></i>{{ item.text }}</router-link
        >
      </div>
      <div class="feedback">
        <a :href="`mailto:${email}`">
          <img src="@/assets/images/question.png" /> 问题反馈
        </a>
      </div>
      <feed-back ref="feedBack" v-model="feedBackDialog"></feed-back>
    </div>
    <div class="menu-hide">
      <img src="@/assets/images/hide.png" alt="" />
    </div>
  </div>
</template>

<script>
import FeedBack from "@/components/FeedBack";
export default {
  name: "Jiashi10Sildernav",
  props: ["value"],
  components: {
    FeedBack,
  },
  data() {
    return {
      feedBackDialog: false,
      navList: [
        {
          id: "101",
          path: "/remoteCollaboration",
          text: "远程协作",
        },
        {
          id: "102",
          path: "/standardOperation",
          text: "标准作业",
        },
        {
          id: "103",
          path: "/aiRecognition",
          text: "AI识别",
        },
        // {
        //   id: '104',
        //   path: '/arWorkbench',
        //   text: 'AR工作台'
        // },
        {
          id: "106",
          path: "/event",
          text: "事件管理",
        },
        {
          id: "105",
          path: "/system",
          text: "系统管理",
        },
      ],
      email: "602008586@qq.com",
    };
  },
  computed: {
    // v-model语法糖：value+@input ，所以这里是设置 外面传值，然后修改外面传值silderNavFlag
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  mounted() {},
  methods: {
    handleFeedBack() {
      this.feedBackDialog = true;
    },
    handleMouseleave($event) {
      $event.currentTarget.className =
        "js-silder-nav animate__animated animate__fadeInDown";
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/nav.scss";
.feedback {
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin:0;
    img {
      display: inline-block;
      width: 26px;
      margin: 0 22px 0 12px;
    }
  }
}
</style>
