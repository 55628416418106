<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'Jiashi10Jsnav',
  data(){
    return{
    }
  },
  mounted(){
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss';
</style>
