export default {
  remoteCollaboration:[{
    id: 1001,
    path: '/remoteCollaboration/initiateCollaboration',
    text: '发起协作'
  },{
    id: 1002,
    path: '/remoteCollaboration/currentCollaboration',
    text: '当前协作'
  },{
    id: 1003,
    path: '/remoteCollaboration/historicalRecords',
    text: '历史记录'
  }],
  standardOperation:[{
    id: 1004,
    path: '/standardOperation/realTimeInformation',
    text: '实时信息'
  },{
    id: 1005,
    path: '/standardOperation/staOperation',
    text: '标准作业'
  },{
    id: 1006,
    path: '/standardOperation/operationRecords',
    text: '作业记录'
  },{
    id: 1007,
    path: '/standardOperation/setLogo',
    text: '企业Logo设置'
  }],
  aiRecognition:[{
    id: 1008,
    path: '/aiRecognition/face',
    text: '人脸识别记录'
  },{
    id: 1009,
    path: '/aiRecognition/car',
    text: '车牌识别记录'
  },{
    id: 1024,
    path: '/aiRecognition/qrcodeRecord',
    text: '二维码记录'
  },{
    id: 1010,
    path: '/aiRecognition/faceData',
    text: '人像数据库'
  },{
    id: 1011,
    path: '/aiRecognition/carData',
    text: '车辆数据库'
  },{
    id: 1023,
    path: '/aiRecognition/qrcode',
    text: '二维码数据库'
  },{
    id: 1012,
    path: '/aiRecognition/dataManage',
    text: '数据统计'
  },
  // 已迁移至租户配置
  // {
  //   id: 1017,
  //   path: '/aiRecognition/aiSystem',
  //   text: 'AI系统配置'
  // }
],
  arWorkbench:[{
    id: 1013,
    path: '/arWorkbench/list',
    text: '资源包'
  }],

  modifyPersonInfo: [],
  modifyPassword: [],
  system: [{
    id: 1014,
    path: '/system/user',
    text: '用户管理'
  },{
    id: 1015,
    path: '/system/authority',
    text: '权限管理'
  },{
    id: 1016,
    path: '/system/role',
    text: '角色管理'
  },{
    id: 1017,
    path: '/system/usage',
    text: '使用情况'
  },{
    id: 101,
    path: '/system/video-tag',
    text: '视频标签管理'
  },{
    id: 102,
    path: '/system/video-search',
    text: '视频搜索'
  }],

  event:[{
    id: 1018,
    path: '/event',
    text: '事件管理'
  },{
    id: 1021,
    path: '/task',
    text: '任务管理'
  },{
    id:1022,
    path:'/record',
    text: '任务列表'
  }]

}