var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible,
          expression: "dialogVisible",
        },
      ],
      ref: "jsSilderNav",
      staticClass: "js-silder-nav animate__animated animate__slideInDown",
      on: {
        mouseleave: function ($event) {
          return _vm.handleMouseleave($event)
        },
      },
    },
    [
      _c("img", {
        staticClass: "jaresh-logo",
        attrs: { src: require("@/assets/images/jaresh.png"), alt: "" },
      }),
      _c(
        "div",
        { staticClass: "scroller" },
        [
          _c(
            "div",
            { staticClass: "router-links" },
            _vm._l(_vm.navList, function (item) {
              return _c(
                "router-link",
                { key: item.id, attrs: { to: item.path } },
                [
                  _c("i", { staticClass: "nav-icon" }),
                  _vm._v(_vm._s(item.text)),
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "feedback" }, [
            _c("a", { attrs: { href: `mailto:${_vm.email}` } }, [
              _c("img", {
                attrs: { src: require("@/assets/images/question.png") },
              }),
              _vm._v(" 问题反馈 "),
            ]),
          ]),
          _c("feed-back", {
            ref: "feedBack",
            model: {
              value: _vm.feedBackDialog,
              callback: function ($$v) {
                _vm.feedBackDialog = $$v
              },
              expression: "feedBackDialog",
            },
          }),
        ],
        1
      ),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menu-hide" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/hide.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }