<template>
  <div class="js-layout">
    <js-nav></js-nav>
    <router-view class="js-content"></router-view>
    <RongCloud></RongCloud>
    <footer class="footer">
      Copyright © 2021 嘉仕企业服务平台 All Rights Reserved 
      <span>京ICP备2022024506号-1</span>
    </footer>
  </div>
</template>

<script>
import JsNav from '@/components/JsNav'
import RongCloud from '@/components/RongCloud/InitialMeeting'

export default {
  name: 'Jiashi10Layout',
  components:{
    JsNav,
    RongCloud
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.js-layout{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  overflow: hidden;
}
</style>