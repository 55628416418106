import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import Utils from './assets/js/common'
import axios from './assets/js/axios'
import * as echarts from 'echarts'
import animate from "animate.css"


Vue.use(animate)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.prototype.$eventBus = new Vue()
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'tp77LYhbQepcLw3yxx5bPeT2HV7oinm2'
})

Vue.prototype.$Utils = Utils
Vue.prototype.$goBack = () => {
  history.go(-1)
}

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if(to.meta && to.meta.fatherName){
    store.commit('setCurrentModule', to.meta.fatherName)
  }
  const isLogin = localStorage.eleToken ? true : false
  if(to.path === '/login' || to.path === '/register'){
    next()
  }else{
    isLogin ? next() : next('/login')
  }
})

// 毫秒转日期时分秒
Vue.filter('timesToDatetime', function (value) {
  let date = new Date(parseInt(value));
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  let h = date.getHours();
  let mm = date.getMinutes();
  let s = date.getSeconds();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  h = h < 10 ? "0" + h : h;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  return y + "-" + m + "-" + d + " " + h + ":" + mm+":"+s;
})

// 毫秒转时长
Vue.filter('timesToFilter', function (value) {
  if(!value){
    return ''
  }
  let t = value / 1000
  let h =  parseInt(t / 3600);
  let mm = parseInt((t % 3600) / 60);
  let s = parseInt(t % 60);

  h = h < 10 ? "0" + h : h;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  return h + ":" + mm+":"+s;
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')