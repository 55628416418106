<template>
  <div class="js-nav">
    <div class="js-topNav">
      <div class="js-topNav-menu">
        <div ref="menu" class="menu">
          <span class="current-menu">{{ getCurrent }} </span>
          <img src="@/assets/images/show-menu.png" alt="" />
        </div>
      </div>
      <top-nav :topNavList="topNavList"></top-nav>
      <div class="js-topNav-about">
        <el-dropdown
          class="drop-about"
          trigger="click"
          @command="handleCommand"
        >
          <!--
          <span class="el-dropdown-link">
            {{ getUserInfo.realName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          -->
          <el-avatar
            :size="40"
            :src="getUserInfo.fullAvatar"
            class="js-topNav-avtar"
          >
          </el-avatar>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">个人信息修改</el-dropdown-item>
            <el-dropdown-item :command="2">密码修改</el-dropdown-item>
            <el-dropdown-item :command="0">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <silder-nav ref="silderNav" v-model="silderNavFlag"></silder-nav>
  </div>
</template>

<script>
import SilderNav from "@/components/SilderNav";
import TopNav from "@/components/TopNav";
import routesArr from "@/router/routes.js";
import { mapGetters } from "vuex";
export default {
  name: "Jiashi10Jsnav",
  components: {
    SilderNav,
    TopNav,
  },
  data() {
    return {
      silderNavFlag: true,
      topNavList: [],
    };
  },
  computed: {
    ...mapGetters(["getUserInfo", "getCurrent"]),
  },
  mounted() {
    this.getSubNanList(this.$route);
    document.addEventListener("click", this.inSilder);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.inSilder);
  },
  watch: {
    $route: function (newVal, oldValue) {
      if (newVal !== oldValue) {
        this.getSubNanList(newVal);
      }
    },
  },
  methods: {
    //获取当前菜单的子菜单
    getSubNanList(route) {
      // console.log(route)
      if (route && route.meta && route.meta.father) {
        let father = route.meta.father;
        this.topNavList = routesArr[father];
        // console.log(this.topNavList)
      }
    },
    // 个人中心
    handleCommand(command) {
      switch (command) {
        case 1:
          this.$router.push({ path: "/modifyPersonInfo" });
          break;
        case 2:
          this.$router.push({ path: "/modifyPassword" });
          break;
        default:
          this.exit();
      }
    },
    exit() {
      const roomId = this.getUserInfo.rtmChannel;

      try {
        // 离开全局room，断开连接
        window.GLOBAL.CommonRongIMLib.quitChatRoom(roomId).then((res) => {
          // 退出聊天室成功
          if (res.code === 0) {
            console.log("退出聊天室 " + roomId);
          }
        });
      } catch (e) {
        console.log(e);
      }

      this.$axios({
        method: "post",
        url: "api/csp/v1/tenant/user/logout",
      }).then((response) => {
        let res = response.data;
        if (!res.status) {
          localStorage.setItem("eleToken", "");
          this.$router.push({ path: "/login" });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
            duration: 1000,
          });
        }
      });
    },
    // silder是否显示
    inSilder(event) {
      const e = event || window.event;

      // 点的是菜单节点的包含节点
      if (this.$refs.menu && this.$refs.menu.contains(e.target)) {
        this.silderNavFlag = !this.silderNavFlag;
      }
      // 点的是sliderNav组件的包含节点，但不是那个收起按钮图片，则显示，否则隐藏
      else if (
        this.$refs.silderNav &&
        this.$refs.silderNav.$refs.jsSilderNav &&
        this.$refs.silderNav.$refs.jsSilderNav.contains(e.target) &&
        e.target.tagName !== "IMG"
      ) {
        this.silderNavFlag = true;
      }
      // 点的是feedBackDialog（反馈弹框）组件的包含节点
      else if (
        this.$refs.silderNav &&
        this.$refs.silderNav.$refs.feedBack &&
        this.$refs.silderNav.$refs.feedBack.$refs.feedBackDialog &&
        this.$refs.silderNav.$refs.feedBack.$refs.feedBackDialog.$refs.dialog.contains(
          e.target
        )
      ) {
        this.silderNavFlag = true;
      } else {
        this.silderNavFlag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/nav.scss";
</style>
