import axios from './axios'
let Utils = {
  getNowDate: ()=> {
    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    m = m < 10 ? '0' + m : m
    d = d < 10 ? '0' + d : d
    return y + '-' + m + '-' + d
  },
  getNowTime: ()=> {
    let date = new Date()
    let h = date.getHours()
    let mm = date.getMinutes()
    let s = date.getSeconds()
    h = h < 10 ? '0' + h : h
    mm = mm < 10 ? '0' + mm : mm
    s = s < 10 ? '0' + s : s
    return h + ':' + mm + ':' + s
  },
  getNowDateTime: ()=> {
    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let mm = date.getMinutes()
    let s = date.getSeconds()
    m = m < 10 ? '0' + m : m
    d = d < 10 ? '0' + d : d
    h = h < 10 ? '0' + h : h
    mm = mm < 10 ? '0' + mm : mm
    s = s < 10 ? '0' + s : s
    return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s
  },
  isObject(obj){
    return Object.prototype.toString.call(obj) === '[object Object]'
  },
  isArray(obj){
    return Object.prototype.toString.call(obj) === '[object Array]'
  },
  deepCopy(obj){
    let result
    if(obj.constructor === Date){
      return new Date(obj)
    }
    if(obj.constructor === RegExp){
      return new RegExp(obj)
    }
    if(typeof obj === 'object'){
      result = this.isArray(obj) ? [] : {}
      for(let i in obj){
        result[i] = this.isObject(obj[i]) || this.isArray(obj[i]) ? this.deepCopy(obj[i]) : obj[i]
      }
    }else{
      result = obj
    }
    return result
  },
  // 返回文件类型
  getFileType(filename){
    if(!filename || typeof filename !== 'string'){
      return false
    }
    let str = filename.split('').reverse().join('')
    let fileType = str.substring(0,str.search(/\./)).split('').reverse().join('')
    return fileType
  },
  canvasToDataURL (canvas, format, quality) {
    return canvas.toDataURL(format||'image/jpeg', quality||1.0)
  },
  dataURLToBlob(dataurl){
    var arr = dataurl.split(',')
    var mime = arr[0].match(/:(.*?);/)[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while(n--){
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type:mime})
  },
  imageToCanvas(src, cb){
    var canvas = document.createElement('CANVAS')
    var ctx = canvas.getContext('2d')
    var img = new Image()
    img.src = src
    img.onload = function (){
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      cb(canvas)
    }
  },
  //image 转 Blob
  imageToBlob(src, cb){
    this.imageToCanvas(src, function (canvas){
        cb(this.dataURLToBlob(this.canvasToDataURL(canvas)))
    })
  },
  fileOrBlobToDataURL(obj, cb){
    var a = new FileReader()
    a.readAsDataURL(obj)
    a.onload = function (e){
      cb(e.target.result)
    }
  },
  //Blob 转 image
  blobToImage(blob, cb) {
    this.fileOrBlobToDataURL(blob, function (dataurl){
      var img = new Image()
      img.src = dataurl
      cb(img)
    })
  },
  // 获取上传文件地址
  getUploadDir(type){
    return new Promise((resolve,reject)=>{
      axios({
        method: 'get',
        url: 'api/csp/cos/v1/url/upload',
        params: {
          dirType: 'upload',
          suffix: type
        }
      }).then((response)=>{
        resolve(response.data)
      }).catch((e)=>{
        reject(e)
      })
    })
  },
  uploadFile(type, url, file){
    return new Promise((resolve,reject)=>{
      let xhr = new XMLHttpRequest()
      xhr.open(type, url, true)
      xhr.onload = (e) => {
        console.log(e)
        console.log('上传成功', xhr.status, xhr.statusText)
        resolve()
      }
      xhr.onerror = (e) => {
        console.log('上传出错', xhr.status, xhr.statusText)
        reject()
      }
      xhr.send(file)
    })
  },
  imageDataToImage(imageData,type){
    let canvas = document.createElement('canvas')
    canvas.width = imageData.width
    canvas.height = imageData.height
    let ctx = canvas.getContext('2d')
    if(!ctx) return
    ctx.putImageData(imageData, 0, 0)
    return canvas.toDataURL()
  }
}

export default Utils