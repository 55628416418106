import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/Layout.vue";
import SubLayout from "../layout/SubLayout.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  // 远程协作
  {
    path: "/remoteCollaboration",
    component: Layout,
    redirect: "/remoteCollaboration/initiateCollaboration",
    children: [
      // rong-cloud-rtc-page   1v1  
      {
        path: "rong-cloud-test-page",
        component: () =>
          import(
            /* webpackChunkName: "CurrentCollaboration" */ "../components/RongCloud/Initial1v1.vue"
          ),
        meta: { father: "remoteCollaboration", fatherName: "远程协作" },
      },
      // rong-cloud-rtc-page   meeting model
      {
        path: "rong-cloud-meeting-page",
        component: () =>
          import(
            /* webpackChunkName: "CurrentCollaboration" */ "../components/RongCloud/InitialMeeting.vue"
          ),
        meta: { father: "remoteCollaboration", fatherName: "远程协作" },
      },
      {
        path: "initiateCollaboration",
        component: () =>
          import(
            /* webpackChunkName: "InitiateCollaboration" */ "../views/InitiateCollaboration.vue"
          ),
        meta: { father: "remoteCollaboration", fatherName: "远程协作" },
      },
      {
        path: "currentCollaboration",
        component: () =>
          import(
            /* webpackChunkName: "CurrentCollaboration" */ "../views/CurrentCollaboration.vue"
          ),
        meta: { father: "remoteCollaboration", fatherName: "远程协作" },
      },
      {
        path: "empty",
        component: () =>
          import(
            /* webpackChunkName: "CurrentCollaboration" */ "../components/Empty.vue"
          ),
        meta: { father: "remoteCollaboration", fatherName: "远程协作" },
      },
      {
        path: "historicalRecords",
        component: SubLayout,
        redirect: "/remoteCollaboration/historicalRecords/list",
        meta: { father: "remoteCollaboration", fatherName: "远程协作" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "Historicalrecords" */ "../views/HistoricalRecords.vue"
              ),
            meta: { father: "remoteCollaboration", fatherName: "远程协作" },
          },
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "RemoteRecord" */ "../views/RemoteRecord.vue"
              ),
            meta: { father: "remoteCollaboration", fatherName: "远程协作" },
          },
        ],
      },
    ],
  },
  // 标准作业
  {
    path: "/standardOperation",
    component: Layout,
    redirect: "/standardOperation/realTimeInformation",
    children: [
      {
        path: "realTimeInformation",
        component: SubLayout,
        redirect: "/standardOperation/realTimeInformation/list",
        meta: { father: "standardOperation", fatherName: "标准作业" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "RealTimeInformation" */ "../views/RealTimeInformation.vue"
              ),
            meta: { father: "standardOperation", fatherName: "标准作业" },
          },
          {
            path: "details/:id",
            component: () =>
              import(
                /* webpackChunkName: "RealTimeInformationDetails" */ "../views/RealTimeInformationDetails.vue"
              ),
            meta: { father: "standardOperation", fatherName: "标准作业" },
          },
        ],
      },
      {
        path: "staOperation",
        component: SubLayout,
        redirect: "/standardOperation/staOperation/list",
        meta: { father: "standardOperation", fatherName: "标准作业" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "StandardOperation" */ "../views/StandardOperation.vue"
              ),
            meta: { father: "standardOperation", fatherName: "标准作业" },
          },
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "StandardOperationDetails" */ "../views/StandardOperationDetails.vue"
              ),
            meta: { father: "standardOperation", fatherName: "标准作业" },
          },
        ],
      },
      {
        path: "operationRecords",
        component: SubLayout,
        redirect: "/standardOperation/operationRecords/list",
        meta: { father: "standardOperation", fatherName: "标准作业" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "OperationRecords" */ "../views/OperationRecords.vue"
              ),
            meta: { father: "standardOperation", fatherName: "标准作业" },
          },
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "OperationDetails" */ "../views/OperationDetails.vue"
              ),
            meta: { father: "standardOperation", fatherName: "标准作业" },
          },
        ],
      },
      {
        path: "setLogo",
        component: () =>
          import(/* webpackChunkName: "SetLogo" */ "../views/SetLogo.vue"),
        meta: { father: "standardOperation", fatherName: "标准作业" },
      },
    ],
  },
  // AI识别
  {
    path: "/aiRecognition",
    component: Layout,
    redirect: "/aiRecognition/face",
    children: [
      {
        path: "face",
        component: SubLayout,
        redirect: "/aiRecognition/face/list",
        meta: { father: "aiRecognition", fatherName: "AI识别" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "FaceRecognition" */ "../views/FaceRecognition.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          {
            path: "faceInfos",
            component: () =>
              import(
                /* webpackChunkName: "FaceInfos" */ "../views/FaceInfos.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
        ],
      },
      {
        path: "car",
        component: SubLayout,
        redirect: "/aiRecognition/car/list",
        meta: { father: "aiRecognition", fatherName: "AI识别" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "CarRecognition" */ "../views/CarRecognition.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          {
            path: "carInfos",
            component: () =>
              import(
                /* webpackChunkName: "CarInfos" */ "../views/CarInfos.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
        ],
      },
      // 二维码库
      {
        path: "qrcode",
        component: SubLayout,
        redirect: "/aiRecognition/qrcode/list",
        meta: { father: "aiRecognition", fatherName: "AI识别" },
        children: [
          // 库列表
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "CarRecognition" */ "../views/Qrcode/List.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          // 二级页面
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "CarInfos" */ "../views/Qrcode/Details.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          // threejs  3D model preview page
          {
            path: "3d-preview",
            component: () =>
              import(
                /* webpackChunkName: "CarInfos" */ "../views/Qrcode/previewModel.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
        ],
      },
      // 二维码记录
      {
        path: "qrcodeRecord",
        component: SubLayout,
        redirect: "/aiRecognition/qrcodeRecord/list",
        meta: { father: "aiRecognition", fatherName: "AI识别" },
        children: [
          // 库列表
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "CarRecognition" */ "../views/QrcodeRecord/List.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          // 二级页面
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "CarInfos" */ "../views/Qrcode/Details.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
        ],
      },
      {
        path: "faceData",
        component: SubLayout,
        redirect: "/aiRecognition/faceData/list",
        meta: { father: "aiRecognition", fatherName: "AI识别" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "FaceRecoData" */ "../views/FaceRecoData.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "FaceRecoDataDetails" */ "../views/FaceRecoDataDetails.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          {
            path: "faceDetails",
            component: () =>
              import(
                /* webpackChunkName: "FaceDetails" */ "../views/FaceDetails.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
        ],
      },
      // 车辆数据库
      {
        path: "carData",
        component: SubLayout,
        redirect: "/aiRecognition/carData/list",
        meta: { father: "aiRecognition", fatherName: "AI识别" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "CarRecoData" */ "../views/CarRecoData.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "CarRecoDataDetails" */ "../views/CarRecoDataDetails.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
          {
            path: "carDetails",
            component: () =>
              import(
                /* webpackChunkName: "CarDetails" */ "../views/CarDetails.vue"
              ),
            meta: { father: "aiRecognition", fatherName: "AI识别" },
          },
        ],
      },
      {
        path: "dataManage",
        component: () =>
          import(
            /* webpackChunkName: "DataManagement" */ "../views/DataManagement.vue"
          ),
        meta: { father: "aiRecognition", fatherName: "AI识别" },
      },
      {
        path: "aiSystem",
        component: () =>
          import(
            /* webpackChunkName: "SystemAiSet" */ "../views/SystemAiSet.vue"
          ),
        meta: { father: "aiRecognition", fatherName: "AI识别" },
      },
    ],
  },
  // AR工作台
  {
    path: "/arWorkbench",
    component: Layout,
    redirect: "/arWorkbench/list",
    children: [
      {
        path: "list",
        component: () =>
          import(
            /* webpackChunkName: "arWorkbench" */ "../views/ArWorkbench.vue"
          ),
        meta: { father: "arWorkbench", fatherName: "AR工作台" },
      },
    ],
  },
  // 系统管理
  {
    path: "/system",
    component: Layout,
    redirect: "/system/user",
    children: [
      {
        path: "user",
        component: SubLayout,
        redirect: "/system/user/index",
        meta: { father: "system", fatherName: "系统管理" },
        children: [
          {
            path: "index",
            component: () =>
              import(
                /* webpackChunkName: "SystemUsers" */ "../views/SystemUsers.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
          {
            path: "createUser",
            component: () =>
              import(
                /* webpackChunkName: "SystemCreateUser" */ "../views/SystemCreateUser.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
          {
            path: "organization",
            component: () =>
              import(
                /* webpackChunkName: "SystemOrganization" */ "../views/SystemOrganization.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
        ],
      },
      {
        path: "authority",
        component: () =>
          import(
            /* webpackChunkName: "SystemAuthority" */ "../views/SystemAuthority.vue"
          ),
        meta: { father: "system", fatherName: "系统管理" },
      },
      {
        path: "usage",
        component: SubLayout,
        redirect: "/system/usage/index",
        meta: { father: "system", fatherName: "系统管理" },
        children: [
          {
            path: "index",
            component: () =>
              import(
                /* webpackChunkName: "SystemUsage" */ "../views/SystemUsage.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
          {
            path: "account",
            component: () =>
              import(
                /* webpackChunkName: "SystemUsageAccount" */ "../views/SystemUsageAccount.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
          {
            path: "installAccount",
            component: () =>
              import(
                /* webpackChunkName: "SystemUsageInstallAccount" */ "../views/SystemUsageInstallAccount.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
          {
            path: "authorize/:id",
            component: () =>
              import(
                /* webpackChunkName: "SystemUsageAuthorize" */ "../views/SystemUsageAuthorize.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
        ],
      },
      {
        path: "role",
        component: SubLayout,
        redirect: "/system/role/list",
        meta: { father: "system", fatherName: "系统管理" },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "SystemRole" */ "../views/SystemRole.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
          {
            path: "details",
            component: () =>
              import(
                /* webpackChunkName: "SystemRoleDetails" */ "../views/SystemRoleDetails.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
        ],
      },
      // /system/video-tag
      {
        path: "video-tag",
        component: SubLayout,
        redirect: "/system/video-tag/index",
        children: [
          {
            path: "index",
            component: () =>
              import(
                /* webpackChunkName: "SystemRole" */ "../views/SystemVideoTag.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
        ],
        meta: { father: "system", fatherName: "系统管理" },
      },
      {
        path: "video-search",
        component: SubLayout,
        redirect: "/system/video-search/index",
        children: [
          {
            path: "index",
            component: () =>
              import(
                /* webpackChunkName: "SystemRole" */ "../views/SystemVideoSearch.vue"
              ),
            meta: { father: "system", fatherName: "系统管理" },
          },
        ],
        meta: { father: "system", fatherName: "系统管理" },
      },
    ],
  },

  // 登录
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "InitiateCollaboration" */ "../views/Login.vue"
      ),
  },
  // 个人信息修改
  {
    path: "/modifyPersonInfo",
    component: Layout,
    redirect: "/modifyPersonInfo/index",
    children: [
      {
        path: "index",
        component: () =>
          import(
            /* webpackChunkName: "ModifyPersonInfo" */ "../views/ModifyPersonInfo.vue"
          ),
        meta: { father: "modifyPersonInfo", fatherName: "个人信息修改" },
      },
    ],
  },
  // 修改密码
  {
    path: "/modifyPassword",
    component: Layout,
    redirect: "/modifyPassword/index",
    children: [
      {
        path: "index",
        component: () =>
          import(
            /* webpackChunkName: "ModifyPassword" */ "../views/ModifyPassword.vue"
          ),
        meta: { father: "modifyPassword", fatherName: "修改密码" },
      },
    ],
  },

  //事件管理
  {
    path: "/event",
    component: Layout,
    children: [
      {
        path: "/event",
        component: SubLayout,
        children: [
          {
            path: "/",
            component: () =>
              import(/* webpackChunkName: "Event" */ "../views/Event.vue"),
            meta: { father: "event", fatherName: "事件管理" },
          },
          {
            path: "eventCreate",
            component: () =>
              import(
                /* webpackChunkName: "EventCreate" */ "../views/EventCreate.vue"
              ),
            meta: { father: "event", fatherName: "事件管理" },
          },
        ],
      },
      {
        path: "/task",
        component: SubLayout,
        children: [
          {
            path: "/",
            component: () =>
              import(/* webpackChunkName: "Task" */ "../views/Task.vue"),
            meta: { father: "event", fatherName: "事件管理" },
          },
          {
            path: "taskCreate",
            component: () =>
              import(
                /* webpackChunkName: "TaskCreate" */ "../views/TaskCreate.vue"
              ),
            meta: { father: "event", fatherName: "事件管理" },
          },
          {
            path: "taskDetailEdit",
            component: () =>
              import(
                /* webpackChunkName: "TaskDetailEdit" */ "../views/TaskDetailEdit.vue"
              ),
            meta: { father: "event", fatherName: "事件管理" },
          },
        ],
      },
      {
        path: "/record",
        component: SubLayout,
        children: [
          {
            path: "/",
            component: () =>
              import(
                /* webpackChunkName: "TaskRecord" */ "../views/TaskRecords.vue"
              ),
            meta: { father: "event", fatherName: "事件管理" },
          },
          {
            path: "taskRecordDetail",
            component: () =>
              import(
                /* webpackChunkName: "TaskRecordDetail" */ "../views/TaskRecordDetail.vue"
              ),
          },
        ],
      },
    ],
  },

  // //任务管理
  // {
  //   path:'/task',
  //   component: Layout,
  //   children:[{
  //     path:'/',
  //     component:() => import(/* webpackChunkName: "Task" */ '../views/Task.vue')
  //   },{
  //     path:'taskCreate',
  //     component:() => import(/* webpackChunkName: "TaskCreate" */ '../views/TaskCreate.vue')
  //   },{
  //     path:'taskManagement',
  //     component:() => import(/* webpackChunkName: "TaskManagement" */ '../views/TaskManagement.vue')
  //   }

  //   ]
  // }
];

export default new VueRouter({
  routes,
});
