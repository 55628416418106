<template>
  <div class="js-subLayout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Jiashi10Sublayout',
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.js-subLayout{
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  overflow: hidden;
}
</style>